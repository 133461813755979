// import React, { useEffect, useState } from "react";
// import { MdClear } from "react-icons/md";
// import { TiTick } from "react-icons/ti";

// import axios from "../api/axios";
// import { useNavigate, useParams } from "react-router";
// import NoFound from "../components/NoFound";
// import Title from "../components/Title";
// import { constant } from "../api/ApiConstant";
// import ConfirmModal from "../components/ConfirmationModal";

// const headers = [
//   "s.No",
//   "user Id",
//   // "Wallet Type",
//   "currency Type",
//   "amount",
//   "free coin",
//   "actions",
// ];

// const filtered = (values) => {
//   return values.filter((val) => val.status === "P");
// };

// const WithdrawRequest = () => {
//   const { userId } = useParams();
//   const [requests, setRequests] = useState([]);
//   const [isOpenAcceptModal, setIsOpenAcceptModal] = useState(false);
//   const [isOpenRejectModal, setIsOpenRejectModal] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [initialLoading, setInitialLoading] = useState(true);
//   const [freeCoinAddress, setFreeCoinAddress] = useState("");

//   const [selectedId, setSelectedId] = useState("");

//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchRequests = async () => {
//       try {
//         const result = await axios.get(${constant.withdraw});
//         setRequests(result.data.data);
//       } catch (error) {
//         console.error(error.message);
//       } finally {
//         setInitialLoading(false);
//       }
//     };
//     fetchRequests();
//   }, []);

//   const fetchFreeCoinAddress = async () => {
//     try {
//       const response = await axios.get(/user/getFreeCoinAddress/${userId});
//       setFreeCoinAddress(response.data.data.freeCoinAddress);
//       console.log(userId);
//     } catch (err) {
//       console.error(err.message);
//     }
//   };
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         await fetchFreeCoinAddress();
//         await fetchRequests();
//       } catch (error) {
//         console.error(error.message);
//       } finally {
//         setInitialLoading(false);
//       }
//     };
//     fetchData();
//   }, []);
//   const handleAccept = async () => {
//     try {
//       setLoading(true);
//       const updatedRequest = requests.find((req) => req.id === selectedId);
//       const response = await axios.put("/user/acceptWithdrawRequest", {
//         ...updatedRequest,
//         status: "A",
//       });
//       if (response.status === 200) {
//         setRequests(
//           requests.map((request) =>
//             request.id === selectedId
//               ? {
//                   ...updatedRequest,
//                   status: "A",
//                 }
//               : request
//           )
//         );
//       }
//       handleAcceptModal();
//       setLoading(false);
//     } catch (err) {
//       console.error(err);
//       setLoading(false);
//     }
//   };
//   const handleReject = async () => {
//     try {
//       setLoading(true);
//       const updatedRequest = requests.find((req) => req.id === selectedId);
//       setRequests(
//         requests.map((request) =>
//           request.id === selectedId
//             ? {
//                 ...updatedRequest,
//                 status: "R",
//               }
//             : request
//         )
//       );
//       const response = await axios.put("user/rejectWithdrawRequest", {
//         ...updatedRequest,
//         status: "R",
//       });
//       if (response.status === 200) {
//         setRequests(
//           requests.map((request) =>
//             request.id === selectedId
//               ? {
//                   ...updatedRequest,
//                   status: "R",
//                 }
//               : request
//           )
//         );
//       }
//       handleRejectModal();
//       setLoading(false);
//     } catch (err) {
//       console.error(err);
//       setLoading(false);
//     }
//   };
//   // fetchFreeCoinAddress();
//   const handleAcceptModal = (id = "") => {
//     setSelectedId(id);
//     setIsOpenAcceptModal(!isOpenAcceptModal);
//   };
//   const handleRejectModal = (id = "") => {
//     setSelectedId(id);
//     setIsOpenRejectModal(!isOpenRejectModal);
//   };

//   if (initialLoading) return <p>Loading...</p>;

//   return (
//     <>
//       <Title title="Withdraw Request" />
//       <button
//         className="py-1 px-3 block sm:hidden mb-3 mx-5 text-md rounded text-white bg-blue-900"
//         onClick={() => navigate(-1)}
//       >
//         back
//       </button>
//       {filtered(requests)?.length === 0 ? (
//         <NoFound message="No Withdraw Requests" />
//       ) : (
//         <div className="sm:m-5 overflow-auto">
//           <table className="table-auto sm:p-5 w-full text-center">
//             <thead className="bg-gray-800 text-white p-2">
//               <tr>
//                 {headers.map((header) => (
//                   <th
//                     className={py-2 sm:p-4 capitalize text-xs sm:text-sm md:text-lg ${
//                       header === "s.No" || header === "username"
//                         ? "hidden sm:block"
//                         : ""
//                     } ${header === "RefName" ? "hidden sm:block" : ""}}
//                     key={header}
//                   >
//                     {header}
//                   </th>
//                 ))}
//               </tr>
//             </thead>
//             <tbody>
//               {filtered(requests)?.map((request, index) => (
//                 <tr key={request.id}>
//                   <td className="hidden sm:h-20 sm:flex sm:items-center sm:justify-center h-full w-full py-1 sm:p-2 text-xxs sm:text-sm md:text-lg">
//                     <span>{index + 1}</span>
//                   </td>
//                   <td className="py-1 sm:p-2 text-xxs sm:text-sm md:text-lg">
//                     {request["refId"]}
//                   </td>
//                   <td className=" py-1 sm:p-2 text-xxs sm:text-sm md:text-lg">
//                     {request["currencyType"] || "-"}
//                   </td>
//                   <td className=" py-1 sm:p-2 text-xxs sm:text-sm md:text-lg">
//                     {request["amount"] || "-"}
//                   </td>
//                   <td className=" py-1 sm:p-2 text-xxs sm:text-sm md:text-lg">

//                   {freeCoinAddress || "-"}

//                 </td>

//                   <td className="py-1 sm:p-2 text-xxs sm:text-sm md:text-lg">
//                     <div className="p-2 flex items-center justify-center rounded">
//                       <div className="shadow p-2 flex items-center justify-center">
//                         <button
//                           className="transform hover:scale-105 hover:text-yellow-500 mr-3 text-xs sm:text-sm md:text-md xl:text-xl"
//                           onClick={() => handleAcceptModal(request.id)}
//                         >
//                           <TiTick color="bg-yellow-500" />
//                         </button>
//                         <button
//                           className="transform hover:scale-105 hover:text-red-500 text-xs sm:text-sm md:text-md xl:text-xl"
//                           onClick={() => handleRejectModal(request.id)}
//                         >
//                           <MdClear color="bg-red-500" />
//                         </button>
//                       </div>
//                     </div>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       )}
//       <ConfirmModal
//         visible={isOpenAcceptModal}
//         yes={handleAccept}
//         no={handleAcceptModal}
//         loading={loading}
//       />
//       <ConfirmModal
//         visible={isOpenRejectModal}
//         yes={handleReject}
//         no={handleRejectModal}
//         loading={loading}
//       />
//     </>
//   );
// };

// export default WithdrawRequest;
import React, { useEffect, useState } from "react";
import { MdClear } from "react-icons/md";
import { TiTick } from "react-icons/ti";
import './Css/WithdrawRequest.css'

import axios from "../api/axios";
import { useNavigate, useParams } from "react-router";
import NoFound from "../components/NoFound";
import Title from "../components/Title";
import { constant } from "../api/ApiConstant";
import ConfirmModal from "../components/ConfirmationModal";
// import { Alert } from 'react-native';


const headers = [
  "s.No",
  "Date",
  "user Id",
  "name",
  // "Wallet Type",
  "currency Type",
  "amount",
  "Deducted",
  "free coin",
  "actions",
];

const filtered = (values) => {
  return values.filter((val) => val.status === "P");
};

const WithdrawRequest = () => {

  const [showPopup, setShowPopup] = useState(false);

  const handleButtonClick = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };
  // const { userId } = useParams();
  const { id } = useParams();

  //
  const [bankDetails, setBankDetails] = useState("");
  // const [panDetails, setPanDetails] = useState("");

  //

  const [requests, setRequests] = useState([]);
  const [isOpenAcceptModal, setIsOpenAcceptModal] = useState(false);
  const [isOpenRejectModal, setIsOpenRejectModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [freeCoinAddress, setFreeCoinAddress] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [eCoin, setECoin] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        const result = await axios.get(`${constant.withdraw}`);
        // const response = await axios.get("/user/");
        // console.log(response, "response detail");

        setRequests(result.data.data);
        console.log(result, "result");
      } catch (error) {
        console.error(error.message);
      } finally {
        setInitialLoading(false);
      }
    };
    fetchRequests();
  }, []);

  // const handleFreeCoin = (id) => {
  //   console.log(id, "id");
  // };

  //started
  const handleFreeCoin = (id) => {
    fetchFreeCoinAddress(id);
  };

  const fetchFreeCoinAddress = async (id) => {
    try {
      const response = await axios.get(`/user/getFreeCoinAddress/${id}`);
      setFreeCoinAddress(response.data.data.freeCoinAddress);
      console.log(response.data.data.freeCoinAddress, "freeCoinAddress");
      setECoin(response.data.data.freeCoinAddress);
      // alert(response.data.data.freeCoinAddress );
    } catch (err) {
      console.error(err.message);
    }
  };
  //ended

  const handleAccept = async () => {
    try {
      setLoading(true);
      const updatedRequest = requests.find((req) => req.id === selectedId);
      const response = await axios.put("/user/acceptWithdrawRequest", {
        ...updatedRequest,
        status: "A",
      });
      if (response.status === 200) {
        setRequests(
          requests.map((request) =>
            request.id === selectedId
              ? {
                  ...updatedRequest,
                  status: "A",
                }
              : request
          )
        );
      }
      handleAcceptModal();
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  const handleReject = async () => {
    try {
      setLoading(true);
      const updatedRequest = requests.find((req) => req.id === selectedId);
      setRequests(
        requests.map((request) =>
          request.id === selectedId
            ? {
                ...updatedRequest,
                status: "R",
              }
            : request
        )
      );
      const response = await axios.put("user/rejectWithdrawRequest", {
        ...updatedRequest,
        status: "R",
      });
      if (response.status === 200) {
        setRequests(
          requests.map((request) =>
            request.id === selectedId
              ? {
                  ...updatedRequest,
                  status: "R",
                }
              : request
          )
        );
      }
      handleRejectModal();
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };
  // const fetchFreeCoinAddress = async () => {
  //   try {
  //     // const response = await axios.get(/user/getFreeCoinAddress/${id});

  //     const response = await axios.get(/user/getFreeCoinAddress);
  //     console.log(response, "response-----");
  //     setFreeCoinAddress(response.data.data.freeCoinAddress);
  //     console.log(freeCoinAddress, "freeCoinAddress");
  //   } catch (err) {
  //     console.error(err.message);
  //   }
  // };
  const handleAcceptModal = (id = "") => {
    setSelectedId(id);
    setIsOpenAcceptModal(!isOpenAcceptModal);
  };
  const handleRejectModal = (id = "") => {
    setSelectedId(id);
    setIsOpenRejectModal(!isOpenRejectModal);
  };
  // fetchFreeCoinAddress();

  if (initialLoading) return <p>Loading...</p>;

  const dateConverter = (timestamp) => {
    const date = new Date(timestamp)
    return date.toLocaleDateString()
  }

  console.log(requests)
  
  return (
    <>
    <div className='relative'>

    {showPopup && (
      <div className="fixed inset-0 flex items-center justify-center z-10">
        <div className="bg-gray-600 rounded-lg p-4 sm:p-8">
          <div className="flex flex-col items-center">
            <h2 className="text-white text-xxs mb-2 sm:text-sm sm:mb-6">{freeCoinAddress}</h2>
           <div className="space-x-4">
            <button
              className="bg-white text-black py-1 px-2 text-xs sm:text-sm md:text-lg"
              onClick={() => {
                navigator.clipboard.writeText(freeCoinAddress);
                // closePopup();
              }}
            >
              Copy
            </button>
            <button
          className="bg-white text-black py-1 px-2 text-xs sm:text-sm md:text-lg"
          onClick={closePopup}
        >
          Close
        </button>
        </div>
           
          </div>
        </div>
      </div>
    )}
    
   
    
      <Title title="Withdraw Request" />
      <button
        className="py-1 px-3 block sm:hidden mb-3 mx-5 text-md rounded text-white bg-blue-900"
        onClick={() => navigate(-1)}
      >
        back
      </button>

      {filtered(requests)?.length === 0 ? (
        <NoFound message="No Withdraw Requests" />
      ) : (
        <div className="sm:m-5 overflow-auto">
          <table className="table-auto sm:p-5 w-full text-center">
            <thead className="bg-gray-800 text-white p-2">
              <tr className="flex justify-evenly">
                {headers.map((header) => (
                  <>
                    <th
                    className={`hidden sm:block py-2 sm:p-4 capitalize text-xs sm:text-sm md:text-lg ${header === "" || header === "username"? "hidden sm:block": ""} ${header === "RefName" ? "hidden sm:block" : ""}`}key={header}>
                    {/* {header === 'currency Type' ? 'CT' : header && header === 'free coin' ? 'C' : header} */}
                    {header}
                    </th>
                    <th
                    className={`block sm:hidden py-2 sm:p-4 capitalize text-xs sm:text-sm md:text-lg ${header === "" || header === "username"? "hidden sm:block": ""} ${header === "RefName" ? "hidden sm:block" : ""}`}key={header}>
                    {header === 'currency Type' ? 'CT' : header && header === 'free coin' ? 'C' : header}
                    {/* {header} */}
                    </th>
                  </>
                ))}
              </tr>
            </thead>
            <tbody>
              {filtered(requests)?.map((request, index) => (
                <tr key={request.id} className="">
                  {console.log(freeCoinAddress)}
                  <td className="sm:h-20  sm:items-center sm:justify-center h-full w-full py-1 sm:p-2 text-xxs sm:text-sm md:text-lg">
                    <span>{index + 1}</span>
                  </td>
                  <td className="py-1 sm:p-2 lg:bg-gray-100 text-xxs sm:text-sm md:text-lg">
                    {dateConverter(request["requestedAt"])}
                    {/* {console.log(dateConverter(request['requestedAt']))} */}
                  </td>
                  <td className="py-1 sm:p-2 text-xxs sm:text-sm md:text-lg">
                    {request["refId"]}
                  </td>
                  <td className="py-1 sm:p-2 text-xxs sm:text-sm md:text-lg">
                    {request["name"]}
                  </td>
                  <td className=" py-1 sm:p-2 text-xxs sm:text-sm md:text-lg">
                    {request["currencyType"] || "-"}
                  </td>
                  <td className=" py-1 sm:p-2 text-xxs sm:text-sm md:text-lg">
                    {request["amount"]  || "-"}
                  </td>
                  <td className=" py-1 sm:p-2 text-xxs sm:text-sm md:text-lg">
                    {request["amount"] * 0.9 || "-"}
                  </td>
                  <td
                    className="py-1 sm:text-center sm:p-2 text-xxs cursor-pointer sm:text-sm md:text-lg"
                    onClick={(e) => handleFreeCoin(request["userId"])}
                  >
                  <button onClick={handleButtonClick}>click here</button>  
                    {/* {/ {request["userId"] || "-"} /} */}
                    {/* {/ {eCoin} /} */}
                  </td>
                  <td className="py-1 sm:p-2 text-xxs sm:text-sm md:text-lg">
                    <div className="p-2 flex items-center justify-center rounded">
                      <div className="shadow p-2 flex items-center justify-center">
                        <button
                          className="transform hover:scale-105 hover:text-yellow-500 mr-3 text-xs sm:text-sm md:text-md xl:text-xl"
                          onClick={() => handleAcceptModal(request.id)}
                        >
                          <TiTick color="bg-yellow-500" />
                        </button>
                        <button
                          className="transform hover:scale-105 hover:text-red-500 text-xs sm:text-sm md:text-md xl:text-xl"
                          onClick={() => handleRejectModal(request.id)}
                        >
                          <MdClear color="bg-red-500" />
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <ConfirmModal
        visible={isOpenAcceptModal}
        yes={handleAccept}
        no={handleAcceptModal}
        loading={loading}
      />
      <ConfirmModal
        visible={isOpenRejectModal}
        yes={handleReject}
        no={handleRejectModal}
        loading={loading}
      />
      </div>
    </>
    
  );
};

export default WithdrawRequest;